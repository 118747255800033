import { Container, styled } from "@mui/material";

export const NavWrapper = styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 5vh;
  background-color: #eaecf0;
  position: fixed;
  top: 0;
  z-index: 1000;
  .logo {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 8vw;
  }

  .projectTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 10vw;
  }
  .avtar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 12vw;
  }
`;
