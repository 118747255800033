import { Box, styled } from "@mui/material";

export const ZoomButtonWrapper = styled(Box)`
  position: fixed;
  bottom: 30px;
  right: 20px;

  .botombtn {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
      0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    background: #fff;
    width: 45px;

    button {
      background: transparent;
      color: #475467;
      font-size: 16px;
      font-weight: 500;
      line-height: 30px;
      border-color: transparent !important;

      span {
        font-size: 34px;
        color: #475467;
      }
    }
  }
`;
