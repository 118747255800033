import { Button, styled } from "@mui/material";

export const ButtonWrapper = styled(Button)`
  width: 3vw;
  height: 3vh;
  border-radius: 5px;
  border: none;
  color: #ffffff;
  background: #0d81e6;
  border: 1px solid #0d81e6;

  &:hover {
    background-color: #0d81e6;
    border: 1px solid #0d81e6;
  }
`;
