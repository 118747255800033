import React from "react";
import Input from "../Input/Input";
import { GoalInputWrapper } from "./GoalInputStyle";
import bg from "../assets/bg.svg";
const GoalInput = () => {
  return (
    <>
      <GoalInputWrapper
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSize: "50 50",
          backgroundRepeat: "repeat",
          backgroundPosition: "center",
        }}
      >
        <div>
          <p>Instruction</p>
          <Input />
          <p>
            Write down a Strategic Goal or what kind of Strategy you’re trying
            to achieve...
          </p>
        </div>
      </GoalInputWrapper>
    </>
  );
};

export default GoalInput;
