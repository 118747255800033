import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import DrawApp from "./Editor-app";

// import "./Editor-app/pwa";
// import "./Editor-app/sentry";
import { BrowserRouter } from "react-router-dom";
// window.__Editor_SHA__ = process.env.REACT_APP_GIT_SHA;
const rootElement = document.getElementById("root")!;
const root = createRoot(rootElement);
root.render(
  <StrictMode>
    <BrowserRouter>
      <DrawApp />
    </BrowserRouter>
  </StrictMode>
);
