import * as React from "react";
import Button from "@mui/material/Button";
import { ZoomButtonWrapper } from "./ZoomButtonStyles";
import ButtonGroup from "@mui/material/ButtonGroup";
const ZoomButton = ({ zoomIn, zoomOut, zoomValue }) => {
  return (
    <ZoomButtonWrapper className="mainbtn">
      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
        className="botombtn"
      >
        <Button
          variant="contained"
          aria-label="outlined primary button group"
          onClick={zoomIn}
        >
          <span>+</span>
        </Button>
        <Button
          variant="contained"
          aria-label="outlined primary button group"
          className="zoomValue"
        >{`${Math.round(zoomValue * 100)}%`}</Button>
        <Button
          variant="contained"
          aria-label="outlined primary button group"
          onClick={zoomOut}
        >
          <span>-</span>
        </Button>
      </ButtonGroup>
    </ZoomButtonWrapper>
  );
};
export default ZoomButton;
