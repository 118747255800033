import { createContext, useState } from "react";

const EditorContext = createContext({});

export const EditorProvider = ({ children }) => {
  // const [data, setData] = useState("");

  const [goal, setGoal] = useState("");
  console.log("");
  return (
    <EditorContext.Provider
      value={{
        goal,
        setGoal,
      }}
    >
      {children}
    </EditorContext.Provider>
  );
};

export default EditorContext;
