import React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { ToolBarWrapper } from "./ToolBarStyle";
// import {
//   eye,
//   upArrow,
//   smile,
//   curveArrow,
//   text,
//   textFrame,
//   cropBox,
//   handicon,
//   selecticon,
// } from "../../utlis/Import";
import eye from "../assets/eye.svg";
import upArrow from "../assets/upArrow.svg";
import smile from "../assets/smile.svg";
import curveArrow from "../assets/curve.svg";
import textFrame from "../assets/textFrame.svg";
import text from "../assets/text.svg";
import cropBox from "../assets/cropBox.svg";
import handicon from "../assets/Hand.svg";
import selecticon from "../assets/Select.svg";

import { Box } from "@mui/material";

const ButtonGroups = ({ grap, pointer, tool, setTool }) => {
  return (
    <>
      <ToolBarWrapper>
        <Box className="grids column">
          <button
            className={`columgrid ${tool === null ? "active" : ""}`}
            // className={`columgrid active`}
            // onClick={pointer}
            onClick={() => setTool(null)}
          >
            <img src={selecticon} alt="cropIcon" />
          </button>
          <button
            className={`columgrid noradius  ${
              tool === "selection" ? "active" : ""
            } `}
            // onClick={grap}
            onClick={() => setTool("selection")}
          >
            <img src={handicon} alt="cropIcon" />
          </button>
        </Box>
        <Box className="grids">
          <Box className="childgrid noborder">
            <button
              className={`columgrid noradius  ${
                tool === "rectangle" ? "active" : ""
              } `}
              // onClick={grap}
              onClick={() => setTool("rectangle")}
            >
              <img src={cropBox} alt="cropIcon" />
            </button>

            {/* <Button>
              <img src={upArrow} alt="arrow" />
            </Button> */}
          </Box>
        </Box>
        <Box className="grids">
          <Box className="childgrid">
            <button
              className={`columgrid noradius  ${
                tool === "text" ? "active" : ""
              } `}
              onClick={() => setTool("text")}
            >
              <img src={text} alt="cropIcon" />
            </button>
          </Box>
          <Box className="childgrid">
            <button
              className={`columgrid noradius  ${
                tool === "line" ? "active" : ""
              } `}
              onClick={() => setTool("line")}
            >
              <img src={curveArrow} alt="cropIcon" />
            </button>
            {/* <Button className="border">
              <img src={upArrow} alt="arrow" />
            </Button> */}
          </Box>
          {/* <Box className="childgrid">
            <img src={smile} alt="cropIcon" />
            <Button className="border">
              <img src={upArrow} alt="arrow" />
            </Button>
          </Box> */}
        </Box>
        {/* <Box className="grids">
          <Box className="childgrid">
            <img src={eye} alt="cropIcon" />
            <Button className="border">
              <img src={upArrow} alt="arrow" />
            </Button>
          </Box>
        </Box> */}
      </ToolBarWrapper>
    </>
  );
};

export default ButtonGroups;
