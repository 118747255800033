import React from "react";
import { NavWrapper } from "./NavStyle";
import Button from "./Button/Button";
import avatar from "../assets/Avatar.svg";
import comment from "../assets/comment.svg";
import logo from "../assets/logo.svg";
import menu from "../assets/menu.svg";
import notification from "../assets/bell.svg";
import angledown from "../assets/angledown.svg";

const Navbar = () => {
  return (
    <>
      <NavWrapper maxWidth="xlg">
        <div className="logo">
          <img src={logo} alt="logo" />
          <img src={menu} alt="menu" />
        </div>
        {/* <div className="text"> */}
        <div className="projectTitle">
          <p>Untitled Project</p>
          <img src={angledown} alt="angledown" />
        </div>
        {/* </div> */}
        <div className="avtar">
          <img src={comment} alt="comment" />
          <img src={notification} alt="notification" />
          <Button>Share </Button>
          <img src={avatar} alt="avatar" />
        </div>
      </NavWrapper>
    </>
  );
};

export default Navbar;
