import { EditorProvider } from "../context/EditorContext";
import { Routes, Route } from "react-router-dom";
import { atom, Provider, useAtom } from "jotai";
import { appJotaiStore } from "./app-jotai";
import GoalInput from "./GoalInputPage/GoalInput";
import Navbar from "./navbar/Nav";
import EditData from "./edit";

const DrawApp = () => {
  return (
    // <TopErrorBoundary>
    <Provider unstable_createStore={() => appJotaiStore}>
      <EditorProvider>
        <Navbar />
        <Routes>
          <Route path="/" element={<GoalInput />} />
          {/* <Route path="/editor" element={<DrawWrapper />} /> */}
          <Route path="/edit" element={<EditData />} />
        </Routes>
      </EditorProvider>
    </Provider>
    // </TopErrorBoundary>
  );
};

export default DrawApp;
