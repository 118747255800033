import React, { useState } from "react";
import { InputWrapper } from "./InputStyle";
import arrow from "../assets/arrow.svg";
import { useNavigate } from "react-router-dom";
import useEditorContext from "../../hooks/useEditorContext";

const Input = () => {
  const { Goal, setGoal } = useEditorContext();
  // const [components, setComponents] = useState([
  //   { id: 1, x: 900, y: 400, text: "", color: "#9747ff" },
  //   // { id: 2, x: 250, y: 250, text: "", color: "#FFFFFF" },
  //   // { id: 3, x: 450, y: 450, text: "", color: "#FFFFFF" },
  // ]);
  const navigate = useNavigate();

  // const strategyhandler = (componentId, newText) => {
  //   setGoal((prevComponents) => {
  //     return prevComponents.map((component) => {
  //       if (component.id === componentId) {
  //         return { ...component, text: newText };
  //       }
  //       return component;
  //     });
  //   });
  // };

  const editorPagehandler = () => {
    navigate("/edit");
  };
  return (
    <>
      <InputWrapper>
        <input onChange={(e) => setGoal(e.target.value)} />
        <img src={arrow} alt="arrow" onClick={editorPagehandler} />
      </InputWrapper>
    </>
  );
};

export default Input;
