import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import rough from "roughjs/bundled/rough.esm";
import getStroke from "perfect-freehand";
import ToolBar from "../ToolBar/ToolBar";
import ZoomButton from "../ButtonGroup/ZoomButton";
import goal from "../assets/Active State.svg";
import pink from "../assets/Objective-Pink.svg";
import yellow from "../assets/Objective-Yellow.svg";
import blue from "../assets/Objective-blue.svg";
import orange from "../assets/Objective-Orange.svg";
import useEditorContext from "../../hooks/useEditorContext";
import bg from "../assets/bg.svg";
const generator = rough.generator();

const createElement = (id, x1, y1, x2, y2, type) => {
  if (type) {
    switch (type) {
      case "line":
      case "rectangle":
        const roughElement =
          type === "line"
            ? generator.line(x1, y1, x2, y2, {
                roughness: 0,
                stroke: "#9747FF",
              })
            : generator.rectangle(x1, y1, x2 - x1, y2 - y1, { roughness: 0 });
        return { id, x1, y1, x2, y2, type, roughElement };
      case "pencil":
        return { id, type, points: [{ x: x1, y: y1 }] };
      case "text":
        return { id, type, x1, y1, x2, y2, text: "" };
      // case "component":
      //   return { id, type, x1, y1, x2, y2, text: "" };
      default:
        throw new Error(`Type not recognised: ${type}`);
    }
  }
};

const nearPoint = (x, y, x1, y1, name) => {
  return Math.abs(x - x1) < 5 && Math.abs(y - y1) < 5 ? name : null;
};

const onLine = (x1, y1, x2, y2, x, y, maxDistance = 1) => {
  const a = { x: x1, y: y1 };
  const b = { x: x2, y: y2 };
  const c = { x, y };
  const offset = distance(a, b) - (distance(a, c) + distance(b, c));
  return Math.abs(offset) < maxDistance ? "inside" : null;
};

const positionWithinElement = (x, y, element) => {
  if (element) {
    const { type, x1, x2, y1, y2 } = element;
    switch (type) {
      case "line":
        const on = onLine(x1, y1, x2, y2, x, y);
        const start = nearPoint(x, y, x1, y1, "start");
        const end = nearPoint(x, y, x2, y2, "end");
        return start || end || on;
      case "rectangle":
        const topLeft = nearPoint(x, y, x1, y1, "tl");
        const topRight = nearPoint(x, y, x2, y1, "tr");
        const bottomLeft = nearPoint(x, y, x1, y2, "bl");
        const bottomRight = nearPoint(x, y, x2, y2, "br");
        const inside =
          x >= x1 && x <= x2 && y >= y1 && y <= y2 ? "inside" : null;
        return topLeft || topRight || bottomLeft || bottomRight || inside;

      case "pencil":
        const betweenAnyPoint = element.points.some((point, index) => {
          const nextPoint = element.points[index + 1];
          if (!nextPoint) return false;
          return (
            onLine(point.x, point.y, nextPoint.x, nextPoint.y, x, y, 5) != null
          );
        });
        return betweenAnyPoint ? "inside" : null;
      case "text":
        const tLeft = nearPoint(x, y, x1, y1, "tl");
        const tRight = nearPoint(x, y, x1 + 200, y1, "tr");
        const bLeft = nearPoint(x, y, x1, y1 + 200, "bl");
        const bRight = nearPoint(x, y, x1 + 200, y1 + 200, "br");
        const insid =
          x >= x1 && x <= x1 + 200 && y >= y1 && y <= y1 + 200
            ? "inside"
            : null;
        return tLeft || tRight || bLeft || bRight || insid;
      // return x >= x1 && x <= x2 && y >= y1 && y <= y2 ? "inside" : null;
      default:
        throw new Error(`Type not recognised: ${type}`);
    }
  }
};

const distance = (a, b) =>
  Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));

const getElementAtPosition = (x, y, elements) => {
  return elements
    .map((element) => ({
      ...element,
      position: positionWithinElement(x, y, element),
    }))
    .find((element) => element.position !== null);
};

const cursorForPosition = (position) => {
  switch (position) {
    case "tl":
    case "br":
    case "start":
    case "end":
      return "nwse-resize";
    case "tr":
    case "bl":
      return "nesw-resize";
    default:
      return "move";
  }
};

const useHistory = (initialState) => {
  const [index, setIndex] = useState(0);
  const [history, setHistory] = useState([initialState]);

  const setState = (action, overwrite = false) => {
    const newState =
      typeof action === "function" ? action(history[index]) : action;
    if (overwrite) {
      const historyCopy = [...history];
      historyCopy[index] = newState;
      setHistory(historyCopy);
    } else {
      const updatedState = [...history].slice(0, index + 1);
      setHistory([...updatedState, newState]);
      setIndex((prevState) => prevState + 1);
    }
  };

  const undo = () => index > 0 && setIndex((prevState) => prevState - 1);
  const redo = () =>
    index < history.length - 1 && setIndex((prevState) => prevState + 1);

  return [history[index], setState, undo, redo];
};

const getSvgPathFromStroke = (stroke) => {
  if (!stroke.length) return "";

  const d = stroke.reduce(
    (acc, [x0, y0], i, arr) => {
      const [x1, y1] = arr[(i + 1) % arr.length];
      acc.push(x0, y0, (x0 + x1) / 2, (y0 + y1) / 2);
      return acc;
    },
    ["M", ...stroke[0], "Q"]
  );

  d.push("Z");
  return d.join(" ");
};

function wrapText(context, text, x, y, maxWidth, lineHeight) {
  var words = text.split(" ");
  var line = "";

  for (var n = 0; n < words.length; n++) {
    var testLine = line + words[n] + " ";
    var metrics = context.measureText(testLine);
    var testWidth = metrics.width;
    if (testWidth > maxWidth && n > 0) {
      context.fillText(line, x + 15, y + 15);
      line = words[n] + " ";
      y += lineHeight;
    } else {
      line = testLine;
    }
  }
  context.fillText(line, x + 15, y + 15);
}

const componentLayout = [goal, pink, blue, yellow, orange];

const drawElement = (roughCanvas, context, element) => {
  if (element) {
    switch (element.type) {
      case "line":
      case "rectangle":
        roughCanvas.draw(element.roughElement);
        break;
      case "pencil":
        const stroke = getSvgPathFromStroke(getStroke(element.points));
        context.fill(new Path2D(stroke));
        break;
      case "text":
        // console.log("roughCanvas>>>>", roughCanvas.canvas.width);

        var maxWidth = 150;
        var lineHeight = 25;
        var x1 = element.x1;
        var y1 = element.y1;
        context.textBaseline = "top";
        context.font = "18px sans-serif";
        context.color = "white";

        const base_image = new Image();
        if (element.id === 0) {
          base_image.src = componentLayout[0];
        } else {
          base_image.src = componentLayout[1];
        }
        base_image.onload = function () {
          context.width = base_image.width;
          context.height = base_image.height;
          context.drawImage(base_image, element.x1, element.y1);
          wrapText(context, element.text, x1, y1, maxWidth, lineHeight);
        };
        // context.restore()
        break;
      default:
        throw new Error(`Type not recognised: ${element.type}`);
    }
  }
};

const App = () => {
  const canvasRef = useRef(null);

  const { goal } = useEditorContext();
  // console.log("goal", goal);
  const [elements, setElements, undo, redo] = useHistory([
    { id: 0, text: goal, type: "text", x1: 351, x2: 651, y1: 362, y2: 686 },
  ]);
  const [action, setAction] = useState("none");
  const [tool, setTool] = useState("text");
  const [selectedElement, setSelectedElement] = useState(null);
  const textAreaRef = useRef();
  // For Infinite
  const [translatePos, setTranslatePos] = useState({
    x: 0,
    y: 0,
  });
  const [scale, setScale] = useState(1.0);
  const scaleMultiplier = 0.8;
  const [mouseDown, setMouseDown] = useState(false);
  const [startDragOffset, setStartDragOffset] = useState({});

  useLayoutEffect(() => {
    const handleResize = () => {
      const canvas = canvasRef.current;
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.scale(scale, scale);
    const roughCanvas = rough.canvas(canvas);
    elements.forEach((element) => {
      if (action === "writing" && selectedElement?.id === element?.id) return;
      drawElement(roughCanvas, context, element);
    });
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [elements, action, selectedElement]);

  const draw = useCallback(
    (translate = null) => {
      const canvas = canvasRef.current;
      const roughCanvas = rough.canvas(canvas);
      const context = canvas.getContext("2d");
      context.save();
      // console.log("translate", translate);
      if (translate) {
        context.translate(translatePos.x, translatePos.y);
      }

      context.clearRect(0, 0, canvas.width, canvas.height);
      context.scale(scale, scale);
      // console.log(elements);
      elements.forEach((element) => {
        if (action === "writing" && selectedElement?.id === element?.id) return;
        drawElement(roughCanvas, context, element);
      });

      // context.restore();
    },
    [action, elements, scale, selectedElement, translatePos.x, translatePos.y]
  );

  useEffect(() => {
    const undoRedoFunction = (event) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "z") {
        if (event.shiftKey) {
          redo();
        } else {
          undo();
        }
      }
    };

    const canvas = canvasRef.current;

    const handleMouseDown = (evt) => {
      setMouseDown(true);
      const startDrag = {
        x: evt.clientX - translatePos.x,
        y: evt.clientY - translatePos.y,
      };
      setStartDragOffset(startDrag);
    };

    const handleMouseUp = () => {
      setMouseDown(false);
    };

    const handleMouseMove = (evt) => {
      if (mouseDown && !tool) {
        const translate = {
          x: evt.clientX - startDragOffset.x,
          y: evt.clientY - startDragOffset.y,
        };
        setTranslatePos(translate);
        // draw(translate);
      }
    };

    canvas.addEventListener("mousedown", handleMouseDown);
    canvas.addEventListener("mouseup", handleMouseUp);
    canvas.addEventListener("mousemove", handleMouseMove);

    document.addEventListener("keydown", undoRedoFunction);

    return () => {
      canvas.removeEventListener("mousedown", handleMouseDown);
      canvas.removeEventListener("mouseup", handleMouseUp);
      canvas.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("keydown", undoRedoFunction);
    };
  }, [undo, redo, mouseDown, draw, startDragOffset, tool]);

  // useEffect(() => {
  //   draw(translatePos, scale);
  // }, [scale, draw, translatePos]);
  useEffect(() => {
    const textArea = textAreaRef.current;
    if (action === "writing") {
      textArea.focus();
      textArea.value = selectedElement.text;
    }
    draw(translatePos, scale);
  }, [action, selectedElement, scale, draw, translatePos]);

  const updateElement = (id, x1, y1, x2, y2, type, options) => {
    const canvas = canvasRef.current;
    // console.log("options", options);

    const elementsCopy = [...elements];

    switch (type) {
      case "line":
      case "rectangle":
        elementsCopy[id] = createElement(id, x1, y1, x2, y2, type);
        break;
      case "pencil":
        elementsCopy[id].points = [
          ...elementsCopy[id].points,
          { x: x2, y: y2 },
        ];
        break;
      case "text":
        const textWidth = canvas
          .getContext("2d")
          .measureText(options.text).width;
        const textHeight = 24;
        elementsCopy[id] = {
          ...createElement(id, x1, y1, x1 + textWidth, y1 + textHeight, type),
          text: options.text,
        };

        break;

      // case "component":
      //   const componentWidth = canvas
      //     .getContext("2d")
      //     .measureText(options.text).width;
      //   const copmonentHeight = 24;
      //   elementsCopy[id] = {
      //     ...createElement(id, x1, y1, x1 + componentWidth, y1 + copmonentHeight, type),
      //     text: options.text,
      //   };

      // break;
      default:
        throw new Error(`Type not recognised: ${type}`);
    }

    setElements(elementsCopy, true);
  };

  const handleZoomIn = () => {
    setScale((prevScale) => prevScale / scaleMultiplier);
    // console.log("ZoomInscale", scale);
    // draw(scale, translatePos);
  };

  const handleZoomOut = () => {
    setScale((prevScale) => prevScale * scaleMultiplier);
    // console.log("ZoomOutscale", scale);
    // draw(scale, translatePos);
  };
  const handleMouseDown = (event) => {
    // setMouseDown(true);
    // startDragOffset.current.x = event.clientX - translatePos.x;
    // startDragOffset.current.y = event.clientY - translatePos.y;

    if (action === "writing") return;
    const { clientX, clientY } = event;
    if (tool === "selection") {
      const element = getElementAtPosition(clientX, clientY, elements);
      // console.log("Element", element);
      if (element) {
        if (element.type === "pencil") {
          const xOffsets = element.points.map((point) => clientX - point.x);
          const yOffsets = element.points.map((point) => clientY - point.y);
          setSelectedElement({ ...element, xOffsets, yOffsets });
        } else {
          const offsetX = clientX - element.x1;
          const offsetY = clientY - element.y1;
          setSelectedElement({ ...element, offsetX, offsetY });
        }

        setElements((prevState) => prevState);

        if (element.position === "inside") {
          setAction("moving");
        } else {
          setAction("resizing");
        }
      }
    } else if (tool === "rectangle" || tool === "line") {
      // console.log("tool1", tool);
      const id = elements.length;
      const element = createElement(
        id,
        clientX,
        clientY,
        clientX,
        clientY,
        tool
      );
      setElements((prevState) => [...prevState, element]);
      setSelectedElement(element);
      setAction(
        tool === "text" ? "writing" : tool == null ? "moving" : "drawing"
      );
    } else {
      return;
    }
  };

  const handleMouseMove = (event) => {
    if (!selectedElement) return;
    const { clientX, clientY } = event;

    if (tool === "selection") {
      const element = getElementAtPosition(clientX, clientY, elements);
      // console.log("el", element);
      event.target.style.cursor = element
        ? cursorForPosition(element.position)
        : "default";
    }

    // console.log(tool, action);

    if (action === "drawing") {
      const index = elements.length - 1;
      const { x1, y1 } = elements[index];
      updateElement(index, x1, y1, clientX, clientY, tool);
    } else if (action === "moving") {
      if (selectedElement.type === "pencil") {
        const newPoints = selectedElement.points.map((_, index) => ({
          x: clientX - selectedElement.xOffsets[index],
          y: clientY - selectedElement.yOffsets[index],
        }));
        const elementsCopy = [...elements];
        elementsCopy[selectedElement.id] = {
          ...elementsCopy[selectedElement.id],
          points: newPoints,
        };
        setElements(elementsCopy, true);
      } else {
        const { id, x1, x2, y1, y2, type, offsetX, offsetY } = selectedElement;
        const width = x2 - x1;
        const height = y2 - y1;
        const newX1 = clientX - offsetX;
        const newY1 = clientY - offsetY;

        const options = type === "text" ? { text: selectedElement.text } : {};
        console.log("selectedElement>>", selectedElement);
        console.log("element>>>", elements);

        // if (selectedElement.id === elements[selectedElement.id]) {
        const el_id = elements[selectedElement.id + 1];
        console.log(elements);
        setTimeout(() => {
          updateElement(
            el_id.id,
            el_id.x1,
            el_id.y1,
            newX1,
            newY1 + 160,
            el_id.type,
            options
          );
        }, 200);
        // }

        updateElement(
          id,
          newX1,
          newY1,
          newX1 + width,
          newY1 + height,
          type,
          options
        );
      }
    }
    //else if (action === "resizing") {
    //   const { id, type, position, ...coordinates } = selectedElement;
    //   const { x1, y1, x2, y2 } = resizedCoordinates(
    //     clientX,
    //     clientY,
    //     position,
    //     coordinates
    //   );
    //   updateElement(id, x1, y1, x2, y2, type);
    // }
  };

  const handleMouseUp = (event) => {
    const { clientX, clientY } = event;
    const element = getElementAtPosition(clientX, clientY, elements);
    if (element) {
      if (element.type === "text") {
        const offsetX = clientX - element.x1;
        const offsetY = clientY - element.y1;
        setSelectedElement({ ...element, offsetX, offsetY });
        setAction("writing");
        return;
      }
    }
    // console.log(selectedElement);
    // if (selectedElement) {
    //   const index = selectedElement.id;
    //   const { id, type } = elements[index];
    //   if (
    //     (action === "drawing" || action === "resizing") &&
    //     adjustmentRequired(type)
    //   ) {
    //     const { x1, y1, x2, y2 } = adjustElementCoordinates(elements[index]);
    //     updateElement(id, x1, y1, x2, y2, type);
    //   }
    // }

    if (action === "writing") return;

    setAction("none");
    setSelectedElement(null);
  };

  const handleBlur = (event) => {
    const { id, x1, y1, type } = selectedElement;
    setAction("none");
    setSelectedElement(null);
    updateElement(id, x1, y1, null, null, type, { text: event.target.value });
  };

  const doubleClickHandler = (event) => {
    if (tool !== "text" || null) return;
    const { clientX, clientY } = event;

    const id = elements.length;
    const element = createElement(id, clientX, clientY, clientX, clientY, tool);
    // console.log(elements);
    const newelement = createElement(
      id + 1,
      elements[0].x1,
      elements[0].y1 + 110,
      clientX,
      clientY + 165,
      "line"
    );
    setElements((prevState) => [...prevState, element, newelement]);
    setSelectedElement(element);
    setAction(
      tool === "text" ? "writing" : tool === null ? "moving" : "drawing"
    );
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.scale(scale, scale);
    const roughCanvas = rough.canvas(canvas);
    elements.forEach((element) => {
      if (action === "writing" && selectedElement?.id === element?.id) return;
      drawElement(roughCanvas, context, element);
    });
  };
  // console.log("Tool", tool);
  // console.log("Scale", scale);

  return (
    <div>
      <div style={{ position: "fixed", bottom: 0, padding: 10 }}>
        <button onClick={undo}>Undo</button>
        <button onClick={redo}>Redo</button>
      </div>
      <div>
        <ToolBar
          // grap={grap}
          // pointer={pointer}
          // cursorType={"cursorType"}
          tool={tool}
          setTool={setTool}
        />
      </div>
      <div>
        <div>
          <ZoomButton
            zoomIn={handleZoomIn}
            zoomOut={handleZoomOut}
            zoomValue={scale}
          />
        </div>
      </div>
      {action === "writing" ? (
        <textarea
          ref={textAreaRef}
          onBlur={handleBlur}
          style={{
            position: "fixed",
            top: selectedElement.y1 - 2,
            left: selectedElement.x1,
            font: "18px sans-serif",
            margin: 10,
            padding: 20,
            border: 0,
            outline: 0,
            maxWidth: 135,
            maxHeight: 220,
            height: 176,
            // border: "1px solid green",
            resize: "none",
            wordWrap: "break-word",
            overflow: "hidden",
            whiteSpace: "initial",
            // background: "transparent",
            // backgroundImage: `url(${selectedElement.id === 0 ? goal : pink})`, //Not Workng
            backgroundImage: `url(${pink})`,
          }}
        />
      ) : null}
      <canvas
        style={{ backgroundImage: `url(${bg})` }}
        ref={canvasRef}
        width={window.innerWidth}
        height={window.innerHeight}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onDoubleClick={doubleClickHandler}
      >
        Canvas
      </canvas>
    </div>
  );
};

export default App;
