import { Box, styled } from "@mui/material";

export const ToolBarWrapper = styled(Box)`
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 16px;
  width: auto;
  height: auto;
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, 0%);
  display: flex;
  flex-direction: row;

  .grids {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    padding: 5px 8px;
    border-right: #eaecf0 solid thin;

    :last-child {
      border-right: none;
    }

    .childgrid {
      display: flex;
      align-items: center;
      border: #eaecf0 solid thin;
      padding: 0;
      margin-right: 10px;
      border-radius: 8px;

      :last-child {
        margin-right: 0px;
      }

      > img {
        padding: 0px 5px;
        height: 30px;
        width: 30px;
      }

      &.noborder {
        border: none;
      }

      .border {
        border-left: #ccc solid thin;
        padding: 15px;
        border-radius: 0px;
      }

      button {
        min-width: auto !important;
      }
    }

    &.column {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0px;

      .columgrid {
        padding: 6px 14px;
        border-radius: 16px 0px 0px 0px;
        border:none;

        &.active{
          background: #0D81E6;
        }

  
      &.noradius {
        border-radius: 0px 0px 0px 16px
      }
    }

  }
`;
