import { Box, styled } from "@mui/material";

export const InputWrapper = styled(Box)`
  input {
    height: 3vh;
    width: 35vw;
    border-radius: 5px;
    border: 1px solid lightgray;
    position: relative;
    padding: 0px 10px;
  }
  img {
    position: absolute;
    margin-top: 0.8vh;
    margin-left: -25px;
  }
`;
