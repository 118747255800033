import { Box, styled } from "@mui/material";

export const GoalInputWrapper = styled(Box)`
height:100vh;
width:100vw;
display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;




  p {
    text-align: start;
    color: #4e586d;
    font-family: Barlow;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
}
  }
`;
